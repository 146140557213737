<template>
  <div class="table-responsive">
    <table class="table card-table table-vcenter text-nowrap datatable">
      <thead>
        <tr>
          <th>Reviewer</th>
          <th>Started</th>
          <th>Completed</th>
          <th>Preview</th>
          <th>Reply</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="review in reviews" :key="review.id">
          <td v-if="review.member.data">
            {{ review.member.data.first_name }}
            {{ review.member.data.last_name }} at
            {{ review.member.data.company_name }}
          </td>
          <td v-else>A member</td>
          <td>{{ relativeDate(review.started_time) }} ago</td>
          <td v-if="review.complete_time">
            {{ relativeDate(review.complete_time) }} ago
          </td>
          <td v-else>Not completed yet.</td>
          <td>
            <span v-if="review.finalized">
              <a
                :href="`${business_chatter_url}review/preview/${review.preview_token}/`"
                @click="trackPreview"
                target="_blank"
              >
                View Response
              </a>
            </span>
            <span v-else>Review not yet complete</span>
          </td>
          <td>
            <a
              :href="`${business_chatter_url}review/respond/${review.preview_token}/`"
              @click="trackRespond"
              target="_blank"
            >
              Reply To Review
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatDistanceToNow, parseISO } from "date-fns";
import ReputationManagementService from "../../../services/ReputationManagementService";

import TrackingService from "@/services/TrackingService";

export default {
  name: "reputation.review-flow.reviews-table",
  props: {
    reviews: Array,
  },
  methods: {
    relativeDate(date) {
      return formatDistanceToNow(parseISO(date));
    },
    trackRespond() {
      TrackingService.track("opened respond to review link");
    },
    trackPreview() {
      TrackingService.track("opened review preview");
    },
  },
  data: () => {
    return {
      business_chatter_url: ReputationManagementService.business_chatter_url,
    };
  },
};
</script>
